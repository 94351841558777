import React from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const LoadingContent = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingImage = styled.img`
  width: 70px;
`;

function Loading() {
  return (
    <LoadingContainer>
      <LoadingContent>
        <LoadingImage src="/assets/images/common/loading.gif" alt="로딩중" />
      </LoadingContent>
    </LoadingContainer>
  );
}

export default Loading;
